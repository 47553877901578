import { useEffect, useState } from 'react';
import tw, { css } from 'twin.macro';
import GlobalStyles from '../../../components/utils/global';
import TwSpinner from '../../../components/utils/tw-spinner';
import Maytronics from '../../../components/svg/maytronics';
import axios from 'axios';
import QueryString from 'qs'
import { Link } from 'gatsby';
import useCart from '../../../hooks/useCart';
const Verify = ({ location }) => {
    const params = new URLSearchParams(location.search);
    const paymentIntent = params.get("payment_intent");
    const paymentIntentSecret = params.get("payment_intent_client_secret");
    const [status, setStatus] = useState(params.get("redirect_status"));
    const [errorMessage, setErrorMessage] = useState('');
    const { clearCart } = useCart();
    const getStorageKey = (paymentIntentSecret) => `ongoing_order_${paymentIntentSecret.slice(-5)}`;

    const submitSalesforceData = async (salesforceData) => {
        const accVariables = salesforceData;
        const datas = {
            grant_type: 'client_credentials',
            client_id: process.env.GATSBY_CLIENT_ID,
            client_secret: process.env.GATSBY_CLIENT_SECRET,
        }

        const {
            data: { access_token },
        } = await axios.post(
            'https://apim.workato.com/oauth2/token',
            QueryString.stringify(datas)
        )

        const config = {
            headers: { Authorization: `Bearer ${access_token}` },
        }

        const { data } = await axios.post(
            'https://apim.workato.com/maytronics/au-e-comm-create-account-contact-in-sf-v1/createaccountacontactsf',
            accVariables,
            config
        )

        console.log(data)
    }

    const submitOrder = async (orderData, salesforceData, paymentIntentSecret) => {
        const variables = orderData;

        const url = '/.netlify/functions/graphql'
        const options = {
            headers: {
                'Content-Type': 'application/json',
            },
        }

        try {
            const {
                data: { data: result, errors },
            } = await axios.post(
                url,
                {
                    query: `mutation payOrder(
		$_id: ID!
		$product: [OrderItemInput]
		$freight: [OrderItemInput]
		$coupon: [CouponInput]
		$fee: [OrderItemInput]
		$discount: Int
		$subtotal: Int
		$tax: Int
		$total: Int
		$orderTotal: Int
		$shipping: OrderAddressInput
		$billing: OrderAddressInput
		$payment: PaymentInput!
		) {
		payOrder(
			_id: $_id
			product: $product
			freight: $freight
			coupon: $coupon
			fee: $fee
			discount: $discount
			subtotal: $subtotal
			tax: $tax
			total: $total
			orderTotal: $orderTotal
			shipping: $shipping
			billing: $billing
			payment: $payment
		) {
			_id
			orderId
			payment {
				stripeChargeId
			}
		}
		}`,
                    variables,
                },
                options
            )

            console.log(JSON.stringify(result, undefined, 2))
            if (errors) throw errors
            if (!result.payOrder.payment.stripeChargeId) {
                throw new Error('payment failed')
            }
            console.log(JSON.stringify(result, undefined, 2))
            if (result.payOrder.payment.stripeChargeId) {
                // NOTE: Created to proceed if salesforce API is down
                try {
                    await submitSalesforceData(salesforceData)
                } catch (error) {
                    console.error(JSON.stringify(error, undefined, 2));
                }
            }

            window.location.href = `/shop/checkout/complete/?order=complete&order_id=${orderData._id}`
        } catch (error) {
            setErrorMessage('Error processing order')
            setStatus('failed')
            const message =
                (error && error[0] && error[0].message) || error
            alert(`Error! ${message}`)
            console.error(JSON.stringify(error, undefined, 2));
        }
    }

    useEffect(() => {
        if (status === 'succeeded' && paymentIntentSecret && paymentIntent) {
            try {
                const storageKey = getStorageKey(paymentIntentSecret);
                const ongoingOrder = JSON.parse(localStorage.getItem(storageKey));
                if (!ongoingOrder) {
                    setErrorMessage('Error processing order')
                    setStatus('failed')
                    return
                }

                // Delete Local Storage after retrieval to avoid duplicate order
                localStorage.removeItem(storageKey);
                clearCart();
                ongoingOrder.orderData.payment = { stripeSourceId: paymentIntent };
                submitOrder(ongoingOrder.orderData, ongoingOrder.salesforceData, paymentIntentSecret);
            } catch (error) {
                const message =
                    (error && error[0] && error[0].message) || error
                alert(`Error! ${message}`)
                setErrorMessage('Error processing order')
                setStatus('failed')
                console.error(JSON.stringify(error, undefined, 2));
            }
        }

        if (status && status !== 'succeeded') {
            setErrorMessage('Error processing order')
        }

        if (!status || !paymentIntent || !paymentIntentSecret) {
            setErrorMessage('The link you access is invalid.')
        }
    }, [])

    const mainBg = css`
        background-color: #e3e7ea;
        margin: 0px;
    `;

    return (
        <div css={[tw`font-display flex flex-col h-screen justify-center items-center`, mainBg]}>
            <GlobalStyles />
            <Maytronics />
            <div css={tw`flex flex-col items-center justify-center text-lg bg-gray-100 rounded p-12 mt-8`}>
                {status === 'succeeded' && 
                    <div css={tw`flex flex-col items-center justify-center`}>
                        <TwSpinner />
                        <span css={tw`mt-4`}>Processing your order. Please don't close this window.</span>
                    </div>
                }

                {errorMessage && 
                    <div css={tw`text-center flex justify-center items-center flex-col`}>
                        <div>{errorMessage}</div>
                        <Link to={'/shop/checkout'} css={tw`block mt-3 bg-yellow-500 hover:bg-yellow-400 font-display text-black text-base px-4 py-1 rounded`}>Go back to Checkout</Link>
                    </div>
                }
            </div>
        </div>
    )
};

export default Verify;